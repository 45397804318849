import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  selectedDivisions: string[];
}

const initialState: InitialState = {
  selectedDivisions: []
};

export const selectedDivisions = createSlice({
  name: 'selectedDivisions',
  initialState,
  reducers: {
    setSelectedDivisions: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedDivisions = payload;
    },
    addSelectedDivisions: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisions = [...state.selectedDivisions, payload];
    },
    removeSelectedDivisions: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisions = state.selectedDivisions.filter((item) => item !== payload);
    },
    resetSelectedDivisions: (state) => {
      state.selectedDivisions = [];
    }
  }
});

export const { setSelectedDivisions, addSelectedDivisions, removeSelectedDivisions, resetSelectedDivisions } = selectedDivisions.actions;
