import { RedoOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Input, message, Modal, Tabs, TabsProps, Typography } from 'antd';
import { MessageType } from 'antd/lib/message';
import Cookies from 'js-cookie';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlternateItemIdEquals, setProductNumbersEqual } from 'redux/services/mrJohnson/inventoryParams';
import { rudyCadabbyApi } from 'redux/services/rudyCadabby';
import { ReduxState } from 'redux/store';

export const BulkSearchModal = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { acuityContext } = useSelector((state: ReduxState) => state.app);

  const id = acuityContext?.selectedCustomer.id;
  const [isOpen, _setIsOpen] = useState(false);
  const splitCondition = /, ?|\n/gm;
  const { data } = rudyCadabbyApi.endpoints.getXrefConfig.useQueryState(id ?? skipToken);
  const [value, _setValue] = useState('');

  const { productNumbersEqual, alternateItemIdsEqual } = useSelector((state: ReduxState) => state.inventoryParams);
  const [tab, setTab] = useState('');
  const tabCookie = Cookies.get('bulkSearchTab');

  /* ******************** Functions ******************** */
  const handleShow = (): void => _setIsOpen(true);
  const handleClose = (): void => {
    _setValue('');
    _setIsOpen(false);
  };

  const dispatch = useDispatch();

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    _setValue(event.target.value);
  };

  const handleSubmit = (): void | MessageType => {
    const searchIds = value.split(splitCondition).filter((item) => item !== '');

    if (searchIds.length > 30)
      return message.error(`No more than 30 products can be searched for at this time. We are working to remove this limitation. Please remove at least ${searchIds.length - 30} product(s)`);

    if (tab === 'productNumbers') dispatch(setProductNumbersEqual(searchIds));
    else dispatch(setAlternateItemIdEquals(searchIds));

    handleClose();
  };

  const handleTabChange = (key: string): void => {
    setTab(key);
    Cookies.set('bulkSearchTab', key);
  };

  const items: TabsProps['items'] = [
    {
      label: <Typography.Text>{data?.alternateItemIdLabel || 'Alternate Item Ids'}</Typography.Text>,
      key: 'alternateItemIds',
      children: <Input.TextArea value={value} onChange={handleValueChange} placeholder={`Enter ${data?.alternateItemIdLabel || 'Alternate Item Ids'}`} autoSize={{ minRows: 15 }} />,
      forceRender: true
    },
    {
      label: 'Product Numbers',
      key: 'productNumbers',
      children: <Input.TextArea value={value} onChange={handleValueChange} placeholder="Enter product numbers" autoSize={{ minRows: 15 }} />,
      forceRender: true
    }
  ];

  return (
    <>
      {(!!productNumbersEqual?.length || !!alternateItemIdsEqual?.length) && (
        <Button
          type="ghost"
          style={{ marginRight: 8 }}
          icon={<RedoOutlined />}
          onClick={(): void => {
            dispatch(setProductNumbersEqual([]));
            dispatch(setAlternateItemIdEquals([]));
          }}>
          Reset Bulk Search
        </Button>
      )}
      {!productNumbersEqual?.length && !alternateItemIdsEqual?.length && (
        <Button onClick={handleShow} type="primary">
          Bulk Search
        </Button>
      )}
      <Modal okText="Search" title="Bulk Search" open={isOpen} onCancel={handleClose} onOk={handleSubmit}>
        <Tabs defaultActiveKey={tabCookie} onChange={handleTabChange} items={items} />
        {/* <Input.TextArea value={value} onChange={handleValueChange} autoSize={{ minRows: 15 }} /> */}
      </Modal>
    </>
  );
};
