import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetDispositionsQuery } from 'redux/services/animals/animalsApi';
import { setDispositionIdsEqual } from 'redux/services/mrJohnson/inventoryParams';
import { ReduxState, useAppDispatch } from 'redux/store';
import { FancySelect } from '../Inputs/FancySelect';

export const DispositionContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  const { dispositionIdsEqual } = useSelector((state: ReduxState) => state.inventoryParams);

  const { animalsDispositionsParams } = useSelector((state: ReduxState) => state);

  const { data, isLoading, isFetching } = useGetDispositionsQuery({ ...animalsDispositionsParams, overrideSkipTake: true });

  const options = data?.data.map((item) => ({ label: item.name, value: item.name }));

  /* ******************** Functions ******************** */
  const handleChange = (value: string[]): void => {
    dispatch(setDispositionIdsEqual(value));
  };

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setDispositionIdsEqual([]));
    };
  }, [dispatch]);

  return <FancySelect width={250} loading={isLoading || isFetching} value={dispositionIdsEqual} mode="multiple" options={options} placeholder="Disposition" onChange={handleChange} />;
};
