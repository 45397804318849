import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductCatalogItem } from 'models/ProductCatalog';

interface InitialState {
  successItems: ProductCatalogItem[];
  errorItems: { errors: string[]; productNumber: string }[];
  importTotal: number;
}

const initialState: InitialState = {
  successItems: [],
  errorItems: [],
  importTotal: 0
};

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    setSuccessItems: (state, { payload }: PayloadAction<InitialState['successItems']>) => {
      state.successItems = payload;
    },
    setErrorItems: (state, { payload }: PayloadAction<InitialState['errorItems']>) => {
      state.errorItems = payload;
    },
    addSuccessItem: (state, { payload }: PayloadAction<InitialState['successItems'][number]>) => {
      state.successItems = [...state.successItems, payload];
    },
    addErrorItem: (state, { payload }: PayloadAction<InitialState['errorItems'][number]>) => {
      state.errorItems = [...state.errorItems, payload];
    },
    setImportTotal: (state, { payload }: PayloadAction<InitialState['importTotal']>) => {
      state.importTotal = payload;
    }
  }
});

export const { setErrorItems, setSuccessItems, addErrorItem, addSuccessItem, setImportTotal } = importSlice.actions;
