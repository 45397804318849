import { About } from 'pages/about/About';
import { Home } from 'pages/home/Home';
import { EditProfilePage } from 'pages/profile/EditProfile';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
    </Routes>
  );
};
