import { Card, Col, Row, Typography } from 'antd';
import { HomeControls } from 'components/molecules/HomeControl';
import { InventoryTable } from 'components/molecules/InventoryTable';
import { Fragment } from 'react';

export const InventoryDetails = (): JSX.Element => {
  /* ******************** Render ******************** */
  return (
    <Fragment>
      <Typography.Title style={{ fontWeight: 'normal' }} level={3}>
        Search Inventory
      </Typography.Title>
      <HomeControls />
      <Row justify="center">
        <Col style={{ marginTop: 16, marginBottom: 16 }} span={24}>
          <Card
            title={
              <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
                Inventory-On-Hand Warehouse Summary
              </Typography.Title>
            }
            headStyle={{ background: 'rgb(206, 213, 242)' }}
            bodyStyle={{ padding: 0 }}
            style={{ height: '100%' }}>
            <InventoryTable />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
