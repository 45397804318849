import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetConditionsQuery } from 'redux/services/animals/animalsApi';
import { setConditionIdsEqual } from 'redux/services/mrJohnson/inventoryParams';
import { ReduxState, useAppDispatch } from 'redux/store';
import { FancySelect } from '../Inputs/FancySelect';

export const ConditionContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  const { animalsConditionsParams } = useSelector((state: ReduxState) => state);

  const { data: conditionsData, isLoading, isFetching } = useGetConditionsQuery({ ...animalsConditionsParams, overrideSkipTake: true });

  const conditionOptions = conditionsData?.data.map((item) => ({ label: item.name, value: item.name }));

  const { conditionIdsEqual } = useSelector((state: ReduxState) => state.inventoryParams);

  /* ******************** Functions ******************** */
  const handleChange = (value: string[]): void => {
    dispatch(setConditionIdsEqual(value));
  };

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setConditionIdsEqual([]));
    };
  }, [dispatch]);

  return <FancySelect width={250} loading={isLoading || isFetching} value={conditionIdsEqual} mode="multiple" options={conditionOptions} placeholder="Condition" onChange={handleChange} />;
};
