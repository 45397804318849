import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setAlternateItemIdContains } from 'redux/services/mrJohnson/inventoryParams';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { ReduxState, useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const AltItemIdContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  const { acuityContext } = useSelector((state: ReduxState) => state.app);

  const id = acuityContext?.selectedCustomer.id;

  const { data, isLoading } = useGetXrefConfigQuery(id ? id : skipToken);

  /* ******************** Functions ******************** */

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setAlternateItemIdContains([value] || undefined));
  }, 500);

  useEffect(() => {
    return (): void => {
      dispatch(setAlternateItemIdContains(undefined));
    };
  }, [dispatch]);

  return (
    <Spin spinning={isLoading}>
      <FancyInput onChange={handleChange} width={250} placeholder={isLoading ? 'Loading' : data?.alternateItemIdLabel} />
    </Spin>
  );
};
