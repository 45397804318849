import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserParams } from 'models/Users';

const initialState: UserParams = {
  businessIds: undefined,
  divisionIds: undefined,
  nameContains: undefined,
  emailContains: undefined,
  includeDeletedData: undefined,
  returnDeletedUsersOnly: undefined,
  orderByField: undefined,
  orderByDirection: undefined,
  pageNumber: 0,
  take: 25
};

export const userParams = createSlice({
  name: 'userParams',
  initialState,
  reducers: {
    setBusinessIds: (state, { payload }: PayloadAction<UserParams['businessIds']>) => {
      state.businessIds = payload;
    },
    setDivisionIds: (state, { payload }: PayloadAction<UserParams['divisionIds']>) => {
      state.divisionIds = payload;
    },
    setNameContains: (state, { payload }: PayloadAction<UserParams['nameContains']>) => {
      state.nameContains = payload;
    },
    setEmailContains: (state, { payload }: PayloadAction<UserParams['emailContains']>) => {
      state.emailContains = payload;
    },
    SetIncludeDeletedData: (state, { payload }: PayloadAction<UserParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setReturnDeletedUsersOnly: (state, { payload }: PayloadAction<UserParams['returnDeletedUsersOnly']>) => {
      state.returnDeletedUsersOnly = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<UserParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<UserParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    incrementPage: (state) => {
      state.pageNumber += 1;
    },
    setTake: (state, { payload }: PayloadAction<UserParams['take']>) => {
      state.take = payload;
    }
  }
});

export const { setBusinessIds, setDivisionIds, setEmailContains, setNameContains, setOrderByDirection, setOrderByField, incrementPage, setReturnDeletedUsersOnly, setTake } = userParams.actions;
