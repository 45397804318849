import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialState {
  inheritanceTypeFilter: ('Division' | 'Business' | 'BusinessWithDivisionOverride')[];
  customerSearch?: string;
}

export const initialState: InitialState = {
  inheritanceTypeFilter: ['Business', 'Division', 'BusinessWithDivisionOverride'],
  customerSearch: undefined
};

export const businessListSlice = createSlice({
  name: 'businessList',
  initialState,
  reducers: {
    setInheritanceTypeFilter: (state, { payload }: PayloadAction<InitialState['inheritanceTypeFilter']>) => {
      state.inheritanceTypeFilter = payload;
    },
    setCustomerSearch: (state, { payload }: PayloadAction<InitialState['customerSearch']>) => {
      state.customerSearch = payload;
    }
  }
});

export const { setInheritanceTypeFilter, setCustomerSearch } = businessListSlice.actions;
