import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Row } from 'antd';
import { ExportInventoryButton } from 'components/atoms/ExportInventoryButton';
import { AltItemIdContainsFilter } from 'components/atoms/Filters/AltItemIdContainsFilter';
import { ConditionContainsFilter } from 'components/atoms/Filters/ConditionContainsFilter';
import { DispositionContainsFilter } from 'components/atoms/Filters/DispositionContainsFilter';
import { ItemIdContainsFilter } from 'components/atoms/Filters/ItemIdContainsFilter';
import { ManufacturerContainsFilter } from 'components/atoms/Filters/ManufacturerContainsFilter';
import { OwnerContainsFilter } from 'components/atoms/Filters/OwnersEqual';
import { WarehouseContainsFilter } from 'components/atoms/Filters/WarehouseContainsFilter';
import { useSelector } from 'react-redux';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { ReduxState } from 'redux/store';
import { BulkSearchModal } from './BulkSearchModal';

export const HomeControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { acuityContext } = useSelector((state: ReduxState) => state.app);
  const id = acuityContext?.selectedCustomer.id;

  const { data } = useGetXrefConfigQuery(id ? id : skipToken);

  return (
    <Row align="top" justify="space-between" style={{ background: 'white', padding: 16 }}>
      <Col>
        <Row gutter={[12, 12]}>
          <Col>
            <AltItemIdContainsFilter />
          </Col>
          <Col>
            <ItemIdContainsFilter />
          </Col>
          <Col>
            <ManufacturerContainsFilter />
          </Col>
          <Col>
            <OwnerContainsFilter />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} gutter={[12, 12]}>
          <Col>
            <ConditionContainsFilter />
          </Col>
          <Col>
            <DispositionContainsFilter />
          </Col>
          <Col>
            <WarehouseContainsFilter />
          </Col>
        </Row>
      </Col>

      <Col>
        <Row justify="end" gutter={[8, 8]}>
          <Col>
            <BulkSearchModal />
          </Col>
          <Col>
            <ExportInventoryButton />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
