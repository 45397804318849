import { useEffect } from 'react';
import { setManufacturerNameContains } from 'redux/services/mrJohnson/inventoryParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const ManufacturerContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setManufacturerNameContains([value] || undefined));
  }, 500);

  useEffect(() => {
    return (): void => {
      dispatch(setManufacturerNameContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput width={250} onChange={handleChange} placeholder="Manufacturer" />;
};
