import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetWarehousesQuery } from 'redux/services/kingLivingston/useKingstonLivingstonService';
import { setWarehouseIdsEqual } from 'redux/services/mrJohnson/inventoryParams';
import { ReduxState, useAppDispatch } from 'redux/store';
import { FancySelect } from '../Inputs/FancySelect';

export const WarehouseContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  const { warehouseIdsEqual } = useSelector((state: ReduxState) => state.inventoryParams);
  const { acuityContext } = useSelector((state: ReduxState) => state.app);

  const { data, isLoading, isFetching } = useGetWarehousesQuery({ dataAreaId: acuityContext?.selectedCustomer.business.dataAreaId, offset: undefined, overrideSkipTake: true });

  const options = data?.data.map((option) => ({ label: option.warehouseId, value: option.warehouseId }));

  /* ******************** Functions ******************** */
  const handleChange = (value: string[]): void => {
    dispatch(setWarehouseIdsEqual(value));
  };

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setWarehouseIdsEqual([]));
    };
  }, [dispatch]);

  return <FancySelect width={250} loading={isLoading || isFetching} allowClear value={warehouseIdsEqual} mode="multiple" options={options} placeholder="Warehouse" onChange={handleChange} />;
};
