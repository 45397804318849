import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  selectedRoles: string[];
}

const initialState: InitialState = {
  selectedRoles: []
};

export const selectedRoles = createSlice({
  name: 'selectedRoles',
  initialState,
  reducers: {
    addRole: (state, { payload }: PayloadAction<string>) => {
      state.selectedRoles = [...state.selectedRoles, payload];
    },
    removeRoles: (state, { payload }: PayloadAction<string>) => {
      state.selectedRoles = state.selectedRoles.filter((item) => item !== payload);
    },
    setRoles: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedRoles = payload;
    },
    resetRoles: (state) => {
      state.selectedRoles = [];
    }
  }
});

export const { addRole, removeRoles, setRoles, resetRoles } = selectedRoles.actions;
