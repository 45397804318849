import { Form, Input, InputProps } from 'antd';
import { useField } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  label?: string;
}

export const TextInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);
  const [localValue, setLocalValue] = useState(value);

  const debounce = useDebouncedCallback((input: string): void => {
    setValue(input);
  }, 500);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalValue(e.target.value);
    debounce(e.target.value);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Input {...rest} value={localValue || undefined} allowClear onChange={handleChange} onFocus={(): void => setTouched(false)} onBlur={(): void => setTouched(true)} style={{ borderRadius: 5 }} />
    </Form.Item>
  );
};
