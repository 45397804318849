import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Users } from 'models/Users';

interface InitialState {
  selectedUsers: Users[];
}

const initialState: InitialState = {
  selectedUsers: []
};

export const selectedUsers = createSlice({
  name: 'selectedUsers',
  initialState,
  reducers: {
    setSelectedUsers: (state, { payload }: PayloadAction<Users[]>) => {
      state.selectedUsers = payload;
    },
    addSelectedUsers: (state, { payload }: PayloadAction<Users>) => {
      state.selectedUsers = [...state.selectedUsers, payload];
    },
    removeSelectedUsers: (state, { payload }: PayloadAction<Users>) => {
      state.selectedUsers = state.selectedUsers.filter((item) => item.user_id !== payload.user_id);
    }
  }
});

export const { setSelectedUsers, addSelectedUsers, removeSelectedUsers } = selectedUsers.actions;
