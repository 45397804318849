import { BackTop, Button, Col, Layout, Row, Spin, Table, Typography } from 'antd';
import { AtatWalker } from 'components/common/AtatWalker';
import { Loader } from 'components/common/Loader';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { InlineStylesModel } from 'models/InlineStylesModel';
import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useGetApiNameQuery as useAbbyCadabbyName, useGetVersionQuery as useAbbyCadabbyVersion } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetApiNameQuery as useAnimalsName, useGetVersionQuery as useAnimalsVersion } from 'redux/services/animals/animalsApi';
import { useGetApiNameQuery as useCookieMonsterName, useGetVersionQuery as useCookieMonsterVersion } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useGetApiNameQuery as useFloBearName, useGetVersionQuery as useFloBearVersion } from 'redux/services/floBear/floBearApi';
import { useGetApiNameQuery as useKingLivingstonName, useGetVersionQuery as useKingLivingstonVersion } from 'redux/services/kingLivingston/useKingstonLivingstonService';
import { useGetApiNameQuery as useMrJohnsonName, useGetVersionQuery as useMrJohnsonVersion } from 'redux/services/mrJohnson/mrJohnsonApi';
import { useGetApiNameQuery as useXrefName, useGetVersionQuery as useXrefVersion } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import '../../common/styles/checkmarkStyle.less';
import { globalStyles } from '../../common/styles/GlobalStyles';

const styles: InlineStylesModel = {
  headerTitle: {
    color: 'rgb(67, 67, 73)',
    font: isMobileOnly ? '500 0.75rem Poppins' : '500 1rem Poppins',
    fontSize: 16
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMobileOnly ? 'center' : 'none'
  },
  statusIcon: {
    fontSize: '1.5rem',
    marginRight: isMobileOnly ? '0px' : '15px'
  },
  statusText: {
    display: isMobileOnly ? 'none' : 'block'
  },
  tableBody: {
    backgroundColor: '#fff',
    fontSize: isMobileOnly ? '0.7em' : '1em',
    borderTop: '2px solid black'
  }
};

enum ApiStatus {
  online = 'Online',
  offline = 'Offline'
}

export const About: React.FunctionComponent = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();

  const { data: mrJohnsonApiVersion, isLoading: isLoadingMrJohnson } = useMrJohnsonVersion();
  const { data: mrJohnsonApiName, isLoading: isLoadingMrJohnsonName } = useMrJohnsonName();

  const { data: animalsApiVersion, isLoading: isLoadingAnimals } = useAnimalsVersion();
  const { data: animalsApiName, isLoading: isLoadingAnimalsName } = useAnimalsName();

  const { data: abbyCadabbyApiVersion, isLoading: isLoadingAbbyCadabby } = useAbbyCadabbyVersion();
  const { data: abbyCadabbyApiName, isLoading: isLoadingAbbyCadabbyName } = useAbbyCadabbyName();

  const { data: floBearApiVersion, isLoading: isLoadingFloBear } = useFloBearVersion();
  const { data: floBearApiName, isLoading: isLoadingFloBearName } = useFloBearName();

  const { data: kingLivingstonApiVersion, isLoading: isLoadingKingLivingston } = useKingLivingstonVersion();
  const { data: kingLivingstonApiName, isLoading: isLoadingKingLivingstonName } = useKingLivingstonName();

  const { data: cookieMonsterApiVersion, isLoading: isLoadingCookieMonster } = useCookieMonsterVersion();
  const { data: cookieMonsterApiName, isLoading: isLoadingCookieMonsterName } = useCookieMonsterName();

  const { data: xRefApiVersion, isLoading: isLoadingXref } = useXrefVersion();
  const { data: xRefApiName, isLoading: isLoadingXrefName } = useXrefName();

  const columnHeaders = [
    {
      dataIndex: 'apiName',
      key: 'apiName',
      title: 'API Name'
    },
    {
      dataIndex: 'version',
      key: 'version',
      title: 'Version'
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status'
    }
  ];
  const handleHomePage = (): void => {
    navigate(`/`);
  };
  /* ******************** Table Data ******************** */
  const mrJohnsonTableData = {
    version: mrJohnsonApiVersion || 'failed to get version...',
    key: mrJohnsonApiName,
    status: !isLoadingMrJohnson ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {mrJohnsonApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{mrJohnsonApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: mrJohnsonApiName ? (
      <Row>
        <Col>{mrJohnsonApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const animalsTableData = {
    version: animalsApiVersion || 'failed to get version...',
    key: animalsApiName,
    status: !isLoadingAnimals ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {animalsApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{animalsApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: animalsApiName ? (
      <Row>
        <Col>{animalsApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const abbyCadabbyTableData = {
    version: abbyCadabbyApiVersion || 'failed to get version...',
    key: abbyCadabbyApiName,
    status: !isLoadingAbbyCadabby ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {abbyCadabbyApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{abbyCadabbyApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: abbyCadabbyApiName ? (
      <Row>
        <Col>{abbyCadabbyApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const floBearTableData = {
    version: floBearApiVersion || 'failed to get version...',
    key: floBearApiName,
    status: !isLoadingFloBear ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {floBearApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{floBearApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: floBearApiName ? (
      <Row>
        <Col>{floBearApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {}
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const kingLivingstonTableData = {
    version: kingLivingstonApiVersion || 'failed to get version...',
    key: kingLivingstonApiName,
    status: !isLoadingKingLivingston ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {kingLivingstonApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{kingLivingstonApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: kingLivingstonApiName ? (
      <Row>
        <Col>{kingLivingstonApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const xRefTableData = {
    version: xRefApiVersion || 'failed to get version...',
    key: xRefApiName,
    status: !isLoadingXrefName ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {xRefApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{xRefApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: xRefApiName ? (
      <Row>
        <Col>{xRefApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const cookieMonsterTableData = {
    version: cookieMonsterApiVersion || 'failed to get version...',
    key: cookieMonsterApiName,
    status: !isLoadingCookieMonster ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {cookieMonsterApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{cookieMonsterApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: cookieMonsterApiName ? (
      <Row>
        <Col>{cookieMonsterApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };

  const mainAppVersion = (): string => {
    const appName = process.env.REACT_APP_APPLICATION_NAME as string;
    const versionNumber = process.env.REACT_APP_VERSION_NUMBER as string;
    const splitVersion = versionNumber.split('-');

    return `${appName} ${splitVersion[splitVersion.length - 1]}`;
  };

  if (isLoadingMrJohnson) {
    return <Loader />;
  }

  return (
    <Layout.Content style={{ ...globalStyles.addressGridContainer }}>
      <BackTop />
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <AntPageTitle text="About Website" />
              <Typography.Link href={`/release-notes/${process.env.REACT_APP_VERSION_NUMBER?.split('-')[0]}`} style={styles.headerTitle}>
                {mainAppVersion()}
              </Typography.Link>
            </Col>
            <Col>
              <Button onClick={handleHomePage} type="primary">
                Back to Home
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={13} sm={24}>
          <Table
            columns={columnHeaders}
            bordered={true}
            pagination={false}
            dataSource={[xRefTableData, abbyCadabbyTableData, floBearTableData, mrJohnsonTableData, animalsTableData, kingLivingstonTableData, cookieMonsterTableData]}
            style={styles.tableBody}
          />
        </Col>
        <Col md={10} sm={24}>
          <AtatWalker />
        </Col>
      </Row>
    </Layout.Content>
  );
};
