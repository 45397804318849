import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  productsView: 'cart' | 'grid';
}

const initialState: InitialState = {
  productsView: 'grid'
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setProductsView: (state, { payload }: PayloadAction<InitialState['productsView']>) => {
      state.productsView = payload;
    }
  }
});

export const { setProductsView } = formSlice.actions;
