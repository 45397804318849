import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setOwnerIdsEqual } from 'redux/services/mrJohnson/inventoryParams';
import { ReduxState, useAppDispatch } from 'redux/store';
import { FancySelect } from '../Inputs/FancySelect';

export const OwnerContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();
  const { acuityContext } = useSelector((state: ReduxState) => state.app);

  const { data: divisionData, isLoading, isFetching } = useGetDivisionQuery(acuityContext?.selectedCustomer.id ?? skipToken);

  const options = divisionData?.assignedOwners.map((item) => ({ label: item, value: item }));

  const { ownerIdsEqual } = useSelector((state: ReduxState) => state.inventoryParams);

  /* ******************** Functions ******************** */
  const handleChange = (value: string[]): void => {
    dispatch(setOwnerIdsEqual(value));
  };

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setOwnerIdsEqual([]));
    };
  }, [dispatch]);

  return <FancySelect width={250} loading={isLoading || isFetching} value={ownerIdsEqual} mode="multiple" options={options} placeholder="Owners" onChange={handleChange} />;
};
