import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_BASE_URL_FLO_BEAR;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_FLO_BEAR;

export const floBearBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  }
});

export const floBearApi = createApi({
  reducerPath: 'floBearApi',
  baseQuery: floBearBaseQuery,
  tagTypes: ['diagnostics'],
  endpoints: (builder) => ({
    /* ****************** Business Endpoints ****************** */
    getApiName: builder.query<string, void>({
      query: () => ({
        url: 'diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetVersionQuery, useGetApiNameQuery } = floBearApi;
