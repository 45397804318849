import { Col, Collapse, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
const { Panel } = Collapse;

export const ReleaseNotesPage = (): JSX.Element => {
  const { version } = useParams();

  return (
    <Fragment>
      <Row justify="space-between" style={{ padding: 10, background: 'rgb(255, 234, 193)', borderRadius: 5 }}>
        <Col>
          <AntPageTitle text={'Release Notes'} />
        </Col>
      </Row>
      <Collapse defaultActiveKey={[version as string]}>
        <Panel header="Version 1.0.0.2" key="1.0.0.2">
          <ul>
            <li>bullet 1</li>
            <li>bullet 2</li>
            <li>bullet 3</li>
          </ul>
        </Panel>
        <Panel header="Version 1.0.0.1" key="1.0.0.1">
          <ul>
            <li>bullet 1</li>
            <li>bullet 2</li>
            <li>bullet 3</li>
          </ul>
        </Panel>
        <Panel header="Version 1.0.0.0" key="1.0.0.0">
          <ul>
            <li>bullet 1</li>
            <li>bullet 2</li>
            <li>bullet 3</li>
          </ul>
        </Panel>
      </Collapse>
    </Fragment>
  );
};
